import React from "react";
import Footer from "../components/Footer";
import ContactMap from "../components/ContactMap";
import FooterInit from "../components/FooterInit";
import HeaderInit from "../components/HeaderInit";



export default function ContactUs() {



    return (
        <div style={{ width: '100%', }}>
            <HeaderInit />
            <section className="with-bg solid-section">

                <div className="theme-back"></div>
                <div className="container page-info">

                    <div className="section-alt-head container-md">

                        <h1 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">Contact Us</h1>

                        <p data-inview-showup="showup-translate-left"></p>
                    </div>


                </div>

                <div className="section-footer">
                    <div className="container" data-inview-showup="showup-translate-down">
                        <ul className="page-path">

                            <li><a href="index.html">Home</a></li>


                            <li className="path-separator"><i className="fas fa-chevron-right" aria-hidden="true"></i></li>

                            <li>Contact Us</li>

                        </ul>
                    </div>
                </div>

            </section>
            <ContactMap />
            <section className="content-section">
                <div className="container">

                    <div className="section-head text-center container-md">

                        <h2 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">Request a Callback</h2>

                        <p data-inview-showup="showup-translate-left">if your need personal assistance, fill the form bellow we will reply back to your asap!</p>
                    </div>
                    <form className="md-col-8 md-col-offs-2" data-inview-showup="showup-translate-down">
                        <div className="field-group">

                            <div className="field-wrap">
                                <input className="field-control" name="name" placeholder="Name" required="required" />




                                <span className="field-back"></span>

                            </div>


                        </div>
                        <div className="field-group">

                            <div className="field-wrap">
                                <input className="field-control" name="email" type="email" placeholder="Email" required="required" />




                                <span className="field-back"></span>

                            </div>


                        </div>
                        <div className="field-group">

                            <div className="field-wrap">
                                <input className="field-control" name="subject" placeholder="Subject" required="required" />




                                <span className="field-back"></span>

                            </div>


                        </div>
                        <div className="field-group">

                            <div className="field-wrap">
                                <textarea className="field-control" name="message" placeholder="Message" required="required"></textarea>




                                <span className="field-back"></span>

                            </div>


                        </div>

                        <div className="btn-block text-center">


                            <button className="btn text-upper ajax-disabled" type="submit">
                                send message
                            </button>

                        </div>

                    </form>

                </div>
            </section>
            <FooterInit />
        </div>
    )
}