import React, { useState } from "react";
import Footer from "../components/Footer";
import ContactMap from "../components/ContactMap";
import FooterInit from "../components/FooterInit";
import { Link } from "react-router-dom";
import HeaderInit from "../components/HeaderInit";
import ServiceModal from "../components/ServiceModal";

const services = [
  {
    id: 1,
    title: "CRACKED SCREEN",
    image: "assets/images/parts/cracked-screen-replacement-service2.png",
    description: "Is your mobile phone screen cracked, damaged, or malfunctioning? Restore clarity and functionality with our professional screen replacement service at MRXpress. Our skilled technicians use high-quality replacement screens to bring your device back to life. Enjoy a clear, vibrant display and seamless touchscreen functionality once again. Don't let a damaged screen hinder your mobile experience – trust us for quick and reliable screen replacements to keep your device looking and functioning like new."
  },
  {
    id: 2,
    title: "BATTERY PROBLEMS",
    image: "assets/images/parts/battery-issue.jpg",
    description: "Discovering that your phone needs a recharge by 12 pm can be incredibly frustrating. If you're experiencing issues like a sluggish performance, random shutdowns, or a battery that can't make it through the day, it's a clear sign that your iPhone requires a battery replacement. At Mr Xpress, we bring the solution to you – a brand-new iPhone battery, delivered right to your location. This not only minimizes the downtime for your device but also saves you from the expense of purchasing a new iPhone. Trust us to keep your iPhone running smoothly with our convenient battery replacement service."
  },
  {
    id: 3,
    title: "CAMERA DAMAGE",
    image: "assets/images/parts/camera-repair.webp",
    description: "When it comes to capturing life's moments, a reliable camera is essential for every mobile phone user. If you find yourself dealing with a malfunctioning or damaged camera, don't let it hinder your ability to snap those precious memories. Our camera replacement services for mobile phones ensure that you can continue to capture life in vibrant detail. At Mr Xpress, we understand the significance of a fully functional camera in your mobile device. Whether your camera is blurry, unresponsive, or experiencing other issues, our skilled technicians are equipped to provide seamless camera replacements. We use high-quality replacement components to restore your mobile phone's camera to its optimal performance. With our camera replacement service, you can once again enjoy crisp and clear photos and videos without the frustration of a compromised camera. Trust Mr Xpress to bring life back to your mobile photography experience, ensuring you never miss a picture-perfect moment."
  },
  {
    id: 4,
    title: "CHARGING PROBLEMS",
    image: "assets/images/parts/charging-repair.png",
    description: "Is your mobile phone struggling to charge or constantly losing battery power? Don't let charging issues disrupt your connectivity. At Mr Xpress, we specialize in swift and effective repairs for mobile phone charging problems. Our expert technicians will diagnose and resolve issues, ensuring your device charges efficiently and stays powered up throughout the day. Say goodbye to charging hassles – trust us to keep your mobile phone connected.When it comes to capturing life's moments, a reliable camera is essential for every mobile phone user. If you find yourself dealing with a malfunctioning or damaged camera, don't let it hinder your ability to snap those precious memories. Our camera replacement services for mobile phones ensure that you can continue to capture life in vibrant detail. At Mr Xpress, we understand the significance of a fully functional camera in your mobile device. Whether your camera is blurry, unresponsive, or experiencing other issues, our skilled technicians are equipped to provide seamless camera replacements. We use high-quality replacement components to restore your mobile phone's camera to its optimal performance. With our camera replacement service, you can once again enjoy crisp and clear photos and videos without the frustration of a compromised camera. Trust Mr Xpress to bring life back to your mobile photography experience, ensuring you never miss a picture-perfect moment."
  },
  {
    id: 5,
    title: "AUDIO PROBLEMS",
    image: "assets/images/parts/audio-repair.png",
    description: "Is your mobile phone experiencing audio problems such as distorted sound, no audio output, or speaker malfunctions? Say goodbye to frustrating audio issues with our expert repair services at Mr Xpress. Our experienced technicians specialize in diagnosing and resolving mobile phone audio problems swiftly. Whether it's a speaker replacement or addressing software-related issues, we'll ensure your device delivers crystal-clear sound quality. Trust us to bring back the joy of clear, uninterrupted audio on your mobile phone. Don't let audio issues disrupt your communication – let Mr Xpress handle the repair for a seamless audio experience."
  },
  {
    id: 6,
    title: "CRACKED BACK GLASS",
    image: "assets/images/parts/backglass-repair.png",
    description: "Revitalize the sleek look of your mobile phone with our back glass replacement service at Mr Xpress. If the back glass of your device is cracked, shattered, or showing signs of wear, our skilled technicians are here to restore it to its original beauty. Using top-quality replacement glass, we ensure a seamless fit and a polished finish, giving your phone a fresh and renewed appearance. Say goodbye to unsightly cracks and hello to a device that looks as good as new. Trust Mr Xpress for efficient and professional back glass replacements to enhance the overall aesthetics of your mobile phone."
  }
];

export default function Services() {
  const [selectedService, setSelectedService] = useState(null);

  const handleOpen = (service) => {
    setSelectedService(service);
  };

  const handleClose = () => {
    setSelectedService(null);
  };

  return (
    <div style={{ width: '100%', }}>
      <HeaderInit />
      <section className="with-bg solid-section">
        <div className="theme-back"></div>
        <div className="container page-info">
          <div className="section-alt-head container-md">
            <h1 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">Services</h1>
          </div>
        </div>
      </section>
      <section className="content-section">
        <div className="container">
          <div className="shuffle-js">
            <div className="row cols-md rows-md shuffle-items">
              {services.map((service) => (
                <div key={service.id} className="shuffle-item col-12 md-col-4 sm-col-6" data-groups='["Smartphone"]'>
                  <div className="item muted-bg" data-inview-showup="showup-scale">
                    <a href="#" className="block-link" onClick={() => handleOpen(service)}>
                      <span className="image-wrap"><img className="image" src={service.image} alt={`${service.title}`} /></span>
                      <span className="hover">
                        <span className="hover-show">
                          <span className="back"></span>
                          <span className="content"><i className="fas fa-search" aria-hidden="true"></i></span>
                        </span>
                      </span>
                    </a>
                    <div className="item-content">
                      <div className="item-title text-upper"><a href="#" onClick={() => handleOpen(service)}>{service.title}</a></div>
                      <div className="item-text">{service.description.substring(0, 100)}...</div>
                      <a href="#" className="btn btn-md btns-bordered text-upper pull-right" onClick={() => handleOpen(service)}>read more</a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="muted-bg solid-section">
        <div className="container">
          <div className="section-head text-center container-md">
            <h2 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">Can't Find What You Are Looking For?</h2>
            <p data-inview-showup="showup-translate-left">Tell us what you're looking for and we'll make a solution for it. Contact us!</p>
          </div>
          <form className="md-col-8 md-col-offs-2" data-inview-showup="showup-translate-down">
            <div className="field-group">
              <div className="field-wrap">
                <input className="field-control" name="name" placeholder="Name" required="required" />
                <span className="field-back"></span>
              </div>
            </div>
            <div className="field-group">
              <div className="field-wrap">
                <input className="field-control" name="email" type="email" placeholder="Email" required="required" />
                <span className="field-back"></span>
              </div>
            </div>
            <div className="field-group">
              <div className="field-wrap">
                <input className="field-control" name="subject" placeholder="Subject" required="required" />
                <span className="field-back"></span>
              </div>
            </div>
            <div className="field-group">
              <div className="field-wrap">
                <textarea className="field-control" name="message" placeholder="Ask your question here..." required="required"></textarea>
                <span className="field-back"></span>
              </div>
            </div>
            <div className="btn-block text-center">
              <button className="btn sm-col-3 text-upper" type="submit">
                submit
              </button>
            </div>
          </form>
        </div>
      </section>
      <FooterInit />
      <ServiceModal open={!!selectedService} handleClose={handleClose} service={selectedService} />
    </div>
  );
}
