import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Paper, Grid, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Api from '../utils/request/http';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import Item from '@mui/material/ListItem';
import { Link,} from 'react-router-dom';
import ResponsiveDialog from '../components/ResponsiveDialog';
import Loader from '../components/Loader';

export default function OrderDetail() {
  const { enqueueSnackbar } = useSnackbar();

  const [showLoader, setShowLoader] = React.useState(true);

  const [technician, setTechnician] = React.useState({});

  const [repairInfo, setRepairInfo] = React.useState([]);

  const [products, setProducts] = React.useState([]);

  const [imgs, setImgs] = React.useState([]);

  const [orderDetail, setOrderDetail] = React.useState({});

  const navigate = useNavigate();

  const params = useParams();

  const dialogTitle = 'Confirm operation'

  const defaultDialogContent = 'Are you sure to cancel the order?'

  const [dialogContent, setDialogContent] =  React.useState(defaultDialogContent);

  const [isOpen, setIsOpen] = React.useState(false)

  const handleConfirmCallback = () => {
      Api.post('/customer/cancelOrder', {orderId: params.orderId})
      .then(res => {
        if (res.code !== 1000) {
          enqueueSnackbar(res.msg,  {variant: 'error'})
        } else {
          enqueueSnackbar('Cancel successfully',  {variant: 'success'})
          setOrderDetail({...orderDetail, ...{order_status: 5}})
        }
        setIsOpen(false)
        
      })
      .catch(err => {
        console.log(err);
      })
  }

  const fetchOrderDetail = () => {
    Api.get('/customer/orderDetail',{orderId: params.orderId})
      .then((data) => {
        setShowLoader(false)
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          setRepairInfo(data.data.repairInfo);
          setProducts(data.data.productList);
          // setPromoInfo(data.data.promotion);
          setOrderDetail(data.data.order)
          setTechnician(data.data.technician)
          setImgs(data.data.imgList)
        }
      })
      .catch((error) => {
        setShowLoader(false)
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };


  const handleCancelOrder = (orderId, orderStatus) => {
    if (orderStatus >= 2) {
      setDialogContent('Cancel order in this stage will be charged $79 as a cancellation fee. Are you sure to cancel the order?')
    } else {
      setDialogContent(defaultDialogContent)
    }
    
    setIsOpen(true)
  }

  React.useEffect(() => {
    fetchOrderDetail();
    // setShowSidebar(1)
  }, []);

  return (
    <Container component="main" maxWidth="lg">
    <Loader showLoader={showLoader} />
    <Box component="form" noValidate sx={{ flexGrow: 1, }}>
      <Toolbar />
      <ResponsiveDialog title={dialogTitle} 
                          content={dialogContent} 
                          isOpen={isOpen} 
                          setIsOpen={setIsOpen}
                          confirmCallback={handleConfirmCallback}
                          />
      <Typography variant="h6" gutterBottom >
        Order summary
      </Typography>
      <TableContainer component={Paper} sx={{ marginBottom: '16px' }}>
        <Table>
          
          <TableBody>
            <TableRow key='orderInfo'>
              <TableCell>Order Number</TableCell>
              <TableCell>Order Date</TableCell>
              {/* <TableCell>Order Price</TableCell> */}
              <TableCell>Order Status</TableCell>
            </TableRow>
              
            <TableRow >
              <TableCell>{orderDetail.order_no}</TableCell>
              <TableCell>{orderDetail.created_date}</TableCell>
              {/* <TableCell>{orderDetail.total_price}</TableCell> */}
              <TableCell>{orderDetail.order_status_desc}</TableCell>
            </TableRow>

            
          </TableBody>
        </Table>
      </TableContainer>
      
        <Typography variant="h6" gutterBottom >
          Repair summary
        </Typography>
        <TableContainer component={Paper} sx={{ marginBottom: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Phone type</TableCell>
                <TableCell>Repair type</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {repairInfo.map((repairItem) => (
                <TableRow key={repairItem.id}>
                  <TableCell>{repairItem.phone_name}</TableCell>
                  <TableCell>{repairItem.repair_name}</TableCell>
                  <TableCell>${repairItem.repair_price}</TableCell>
                </TableRow>
              ))}
                
                <TableRow >
                  <TableCell>Subtotal</TableCell>
                  <TableCell></TableCell>
                  <TableCell>${orderDetail.repairPrice}</TableCell>
                </TableRow> 
              
              {imgs.length > 0 ? (
                <TableRow >
                <TableCell colSpan={3}>
                {imgs.map((img, index)=>(
                  <img key={index} src={img.img_path} alt="Repair" style={{ width: '64px', height: '64px',marginRight:'10px' }} />
                ))}
                </TableCell>
              </TableRow>) : null}

              {orderDetail.promotion_code && orderDetail.promotion_type == 1 && orderDetail.reduced_money > 0 ? (
                <TableRow >
                  <TableCell>Reduced:</TableCell>
                  <TableCell></TableCell>
                  <TableCell>- ${orderDetail.repair_reduced}</TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      {products.length > 0 ? (
        <>
        <Typography variant="h6" gutterBottom>
        Accessories summary
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>
                    <img src={product.acc_image} alt={product.acc_name} style={{ width: '64px', height: '64px' }} />
                    <Typography variant="body1">{product.acc_name}</Typography>
                  </TableCell>
                  <TableCell>{product.quantity}</TableCell>
                  <TableCell>${product.price}</TableCell>
                </TableRow>
              ))}
                <TableRow >
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell >Subtotal: ${orderDetail.productPrice}</TableCell>
                </TableRow>
                {orderDetail.promotion_code && orderDetail.promotion_type == 2 && orderDetail.reduced_money > 0 ? (
                  <TableRow >
                    <TableCell>Reduced:</TableCell>
                    <TableCell></TableCell>
                    <TableCell rowSpan={2}>- ${orderDetail.product_reduced}</TableCell>
                  </TableRow>
                ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        </>
      ) : null}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Repair schedule
            </Typography>
            <Typography gutterBottom><b>Full name: </b>{orderDetail.first_name} {orderDetail.last_name}</Typography>
            <Typography gutterBottom><b>Mobile: </b>{orderDetail.mobile}</Typography>
            <Typography gutterBottom><b>Address: </b>{orderDetail.address}</Typography>
            <Typography gutterBottom><b>Preferred time: </b>{orderDetail.prefer_time}</Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Technician summary
            </Typography>

            <Typography gutterBottom><b>Technician: </b>{technician.first_name} {technician.last_name}</Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              Price summary
            </Typography>
            <Typography gutterBottom><b>Promotion code: </b>{ orderDetail.product_reduced >0 || orderDetail.repair_reduced > 0 ? '- $' + (parseFloat(orderDetail.product_reduced) + parseFloat(orderDetail.repair_reduced)): 'None'}</Typography>
            <Typography gutterBottom><b>Bonus credits:</b>{ orderDetail.balance_amount && orderDetail.balance_amount > 0 ? '- $' + orderDetail.balance_amount : '$0.00'}</Typography>
            <Typography gutterBottom><b>Card processing fee: </b>${orderDetail.payment_fee}</Typography>
            <Typography gutterBottom><b>Total: </b>${orderDetail.total_price}</Typography>
          </Grid>
        </Grid>
      

        <Grid container spacing={2}>
          
        </Grid>

        <Grid container spacing={2}>
          
          
          
        </Grid>
        
        <Grid container spacing={2} sx={{mt: 3}}>
          <Grid item xs={12}>
            <Button sx={{mr: 1}} variant="contained" color="primary" onClick={() => {navigate(-1);}}>
              Back
            </Button>

            {[1, 2, 3].includes(orderDetail.order_status) ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {handleCancelOrder(orderDetail.id, orderDetail.order_status)}}
                >
                  Cancel Order
                </Button>
              ) : null}
          </Grid>
        </Grid>  
    </Box>
    </Container>
  );
}