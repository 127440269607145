import * as React from 'react';
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Repair from './Repair';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import FooterInit from '../components/FooterInit';
import Cookies from 'js-cookie';
import { useNavigate, useBlocker } from 'react-router-dom';
import HeaderInit from '../components/HeaderInit';

export default function Stepper({setShowSidebar}) {
  
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    
    const [stripePromise, setStripePromise] = useState(null)

    const handleStripPK = () => {
      Api.get('/payment/stripPK', {})
        .then((data) => {
          console.log('data:',data);
          if (data.code !== 1000) {
            enqueueSnackbar(data.msg,  {variant: 'error'})
          } else {
            setStripePromise(loadStripe(data.data.pub_key));
          }
        }
        )
        .catch((error) => {
          console.log('error:',error);
          enqueueSnackbar('Network error',  {variant: 'error'})
        }
        );
    }

    const userToken = Cookies.get('acc_token')
    React.useEffect(() => {
      if (userToken) {
        handleStripPK()
      } else {
        // navigate('/SignIn')
      }
      // setShowSidebar(0)
    }, []);

    // if (!userToken) {
    //   enqueueSnackbar('Please login first',  {variant: 'error'})
      
    //   console.log('userToken:')
    //   return
    // }

    return <Elements stripe={stripePromise}>
            <div style={{width: '100%'}}>
            <HeaderInit />
            <Box component="main" sx={{ flexGrow: 1,  pb: 6}} >
                <Repair />
            </Box>
            <FooterInit />
            </div> 
          </Elements>
}


