import React from 'react';

export default function PrivacyPolicy() {
    return (
        <div className="container legal-doc-styling">
            <h1>Privacy Policy</h1>
            <p>
                MR Xpress Privacy Policy<br/>
                    Effective Date: 21 May 2024
            </p>
            <p>
                MR Xpress Pty Ltd ("MR Xpress," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Services.
            </p>
            <div>
                <p class="section-title">1. Information We Collect</p>
                <ul>
                    <li>Personal Data: Includes your name, email address, phone number, and payment information.</li>
                    <li>Usage Data: Includes information about how you use our Services, such as access times, browser types, and pages visited.</li>
                    <li>Device Data: Includes information about the device you use to access our Services, such as IP address, device type, and operating system.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">2. How We Use Your Information</p>
                <ul>
                    <li>To Provide Services: We use your information to facilitate transactions between customers and technicians.</li>
                    <li>To Improve Services: We use your information to improve our platform, troubleshoot issues, and enhance user experience.</li>
                    <li>To Communicate: We use your information to send you updates, promotional materials, and other information related to our Services.</li>
                    <li>To Comply with Legal Obligations: We may use your information as required by law or to protect our rights and interests.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">3. Sharing Your Information</p>
                <ul>
                    <li>With Service Providers: We may share your information with third-party service providers to perform services on our behalf.</li>
                    <li>With Technicians: We share customer information with technicians to facilitate the repair services.</li>
                    <li>For Legal Reasons: We may disclose your information if required by law or in response to valid requests by public authorities.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">4. Data Security</p>
                <ul>
                    <li>Protection Measures: We implement security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.</li>
                    <li>Data Retention: We retain your information for as long as necessary to provide our Services and fulfill the purposes outlined in this Privacy Policy.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">5. Your Rights</p>
                <ul>
                    <li>Access and Correction: You have the right to access and correct your personal information.</li>
                    <li>Data Deletion: You have the right to request the deletion of your personal information.</li>
                    <li>Objection to Processing: You have the right to object to the processing of your personal information.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">6. Cookies and Tracking Technologies</p>
                <ul>
                    <li>Usage of Cookies: We use cookies to enhance your experience on our platform.</li>
                    <li>Managing Cookies: You can manage your cookie preferences through your browser settings.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">7. Changes to This Privacy Policy</p>
                <ul>
                    <li>Amendments: We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.</li>
                </ul>
            </div>
            <div>
                <p class="section-title">8. Contact Information</p>
                <p>For any questions or concerns about this Privacy Policy, please contact us at info@mrxpress.com.au.</p>
            </div>
        </div>
    );
}
